import { Container } from '@chakra-ui/react'

import Button from '../../styles/shared/Button'

import {
  HeaderContent,
  HeaderDescription,
  HeaderSection,
  HeaderTitle,
  HeaderWave,
} from '../../styles/components/home/Header'
import Router from 'next/router'

export default function Header() {
  return (
    <HeaderSection id="header">
      <Container maxWidth="container.xl">
        <HeaderContent>
          <HeaderTitle>
            Somos a solução poderosa em servidores dedicados
          </HeaderTitle>
          <HeaderDescription>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugiat ea
            neque eius voluptate dolor totam. Ab, vero hic, provident delectus
            atque ullam sunt quidem consequatur libero sequi dolores magnam in!
          </HeaderDescription>
          <Button onClick={() => Router.push('/vps')}>
            Conheça nossos planos de VPS
          </Button>
        </HeaderContent>
      </Container>
      <HeaderWave
        src="/waves/header.svg"
        width="auto"
        height="auto"
        alt="Wave"
        onDragStart={(event) => event.preventDefault()}
      />
    </HeaderSection>
  )
}
