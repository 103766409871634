import styled from '@emotion/styled'

export const FeaturesSection = styled.div`
  padding-block: 100px;
  background: #1a1c1e;
`

export const FeaturesContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const FeaturesTitle = styled.h2`
  font-size: 40px;
  font-weight: 600;
  max-width: 500px;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`

export const FeaturesDescription = styled.p`
  margin-block: 20px;
  font-weight: 300;
  opacity: 0.8;
  max-width: 500px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const Feature = styled.div`
  display: flex;
  flex-direction: column;
`

export const FeatureIcon = styled.div`
  background: #2b2f33;
  color: #0bc5ea;
  padding: 20px;
  padding-inline: 25px;
  border-radius: 100%;
  width: fit-content;
  font-size: 20px;
  font-weight: 700;
`

export const FeatureTitle = styled.h4`
  margin-block: 10px;
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const FeatureDescription = styled.p`
  font-weight: 300;
  opacity: 0.8;
  max-width: 500px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`
