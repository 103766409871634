import type { NextPage } from 'next'

import Layout from '../layouts/website/Layout'

import Header from '../components/home/Header'
import Features from '../components/home/Features'
import Benefit from '../components/home/Benefit'
import Questions from '../components/shared/Questions'

const Home: NextPage = () => {
  return (
    <Layout>
      <Header />
      <Features />
      <Benefit />
      <Questions />
    </Layout>
  )
}

export default Home
