import { Container, SimpleGrid } from '@chakra-ui/react'

import Icon from '../../styles/shared/Icon'

import {
  Feature,
  FeatureDescription,
  FeatureIcon,
  FeaturesContent,
  FeaturesDescription,
  FeaturesSection,
  FeaturesTitle,
  FeatureTitle,
} from '../../styles/components/home/Features'

export default function Features() {
  return (
    <FeaturesSection id="features">
      <Container maxWidth="container.xl">
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          alignItems="center"
          spacing={10}
        >
          <FeaturesContent>
            <FeaturesTitle>
              Está esperando oque para adquirir seu servidor
            </FeaturesTitle>
            <FeaturesDescription>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus
              officiis quisquam in error ratione a at, rem praesentium ullam
              eius. Ad, reprehenderit? Inventore delectus dolore odio libero
              labore repellendus expedita.
            </FeaturesDescription>
          </FeaturesContent>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Feature>
              <FeatureIcon>
                <Icon className="fa-solid fa-coins" />
              </FeatureIcon>
              <FeatureTitle>Garantia</FeatureTitle>
              <FeatureDescription>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga,
                natus ea!
              </FeatureDescription>
            </Feature>
            <Feature>
              <FeatureIcon>
                <Icon className="fa-solid fa-fingerprint" />
              </FeatureIcon>
              <FeatureTitle>Segurança</FeatureTitle>
              <FeatureDescription>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga,
                natus ea!
              </FeatureDescription>
            </Feature>
            <Feature>
              <FeatureIcon>
                <Icon className="fa-solid fa-shield-halved" />
              </FeatureIcon>
              <FeatureTitle>Proteção</FeatureTitle>
              <FeatureDescription>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga,
                natus ea!
              </FeatureDescription>
            </Feature>
            <Feature>
              <FeatureIcon>
                <Icon className="fa-solid fa-headset" />
              </FeatureIcon>
              <FeatureTitle>Suporte</FeatureTitle>
              <FeatureDescription>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga,
                natus ea!
              </FeatureDescription>
            </Feature>
          </SimpleGrid>
        </SimpleGrid>
      </Container>
    </FeaturesSection>
  )
}
